.mainContainer {
    width: 100%;
    margin-bottom: 20vh;

    .title {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin-top: 10vh;
        margin-bottom: 4rem;
    }

    .schoolsList {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .schoolCard {
            border-radius: 10px;
            background-color: #f5f5f5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }

            img {
                width: 100%;
                height: 240px;
                object-fit: cover;
            }

            h2 {
                padding: 1rem;
                font-size: 1.2rem;
                font-weight: 500;
                text-align: center;
                white-space: pre-wrap;
            }
        }
    }
}
