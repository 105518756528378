.mainContainer {
    width: 50%;
    margin: 0 auto 20vh auto;

    .title {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin-top: 10vh;
        margin-bottom: 4rem;
    }
}

@media (max-width: 1060px) {
    .mainContainer {
        width: 100%;
        margin: 0 auto 20vh auto;
    }
}
