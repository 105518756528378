* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden !important;
  margin: 0;
  font-family: "Poppins", sans-serif, monospace;
}

code {
  font-family: "Poppins", sans-serif, monospace;
}

.link {
  color: #000;
}

.link:hover {
  color: #000 !important;
}

.dropdown-menu {
  padding: 0px !important;
}

.primaryButton {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(99, 102, 241);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px;
  border-radius: 12px;
  text-transform: none;
  padding: 8px 20px;
}

.primaryButton:hover {
  text-decoration: none;
  background-color: rgb(67, 56, 202);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 10px;
}

/* .primaryButton:focus {
  outline: none;
  background-color: rgb(67, 56, 202);
  border: 0px;
  color: inherit;
  color: #fff;
  box-shadow: unset;
} */
.secondaryButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px currentcolor;
  margin: 0px;
  background-color: rgb(221, 80, 68); /* Error button color */
  cursor: pointer;
  color: white;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  color: inherit;
  border-radius: 12px;
  text-transform: none;
  padding: 9px 16px;
}

.secondaryButton:hover {
  text-decoration: none;
  background-color: rgba(221, 80, 68, 0.8); /* Error button color on hover */
}

.secondaryButton:focus {
  outline: none;
  background-color: rgba(
    221,
    80,
    68,
    0.8
  ); /* Error button color when focused */
  border: 0px;
  color: inherit;
  color: #fff;
  box-shadow: unset;
}

.table-responsive-xl {
  overflow-x: scroll;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.nav-link.active {
  outline: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #1c2536;
  color: white;
}

/* .reactgrid {
	width: 100% !important;
}

.reactgrid-content {
	width: 100% !important;
}
.rg-cell.rg-header-cell{
	font-weight: 500;
	font-size: 22px!important;
	height: 30px!important;
}

.rg-cell.rg-text-cell,.rg-cell.rg-checkbox-cell{
	padding-top: 4px!important;
} */
.schemeOfWorkLoader,
.schemeOfPlanLoader,
.lessonPlanLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8em;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 10%;
}
.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.mmb-5{
  margin-bottom: 200px;
}