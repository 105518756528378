.mainContainer {
	
	display: flex;
	justify-content: center;
}

.contentContainer {
	overflow: hidden;
	width: 100vw;
	// max-width: 1900px;
}
a {
	text-decoration: none;
	
}
a:hover{
	text-decoration: none;
	color: white;
}