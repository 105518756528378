.detailCard {
    label {
        background-color: rgb(235, 235, 235);
        width: 100%;
        border-radius: 4px;
        padding: 10px;
    }

    p {
        padding-left: 10px;
    }
}