@import '../../../utils/sass/mixins.scss';

.pupil {
    &:hover {
        background-color: rgb(239, 239, 239);
    }

    .pupilName {
        cursor: pointer;
    }
}

.searchButton {
    @include primaryButton();

    border-radius: 6px;
    margin-left: 10px;
    padding: 8px 30px;
}
