.mainContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f857a6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff5858,
    #f857a6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff5858,
    #f857a6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.innerContainer {
  background-color: rgba(255, 255, 255, 0.716);
  width: 500px;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
}

.inputsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 6px;
  padding: 30px 0 10px;

  .loginText {
    position: absolute;
    left: 20px;
    top: -10px;
    background-color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
  }
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0; // Slightly larger margin for more fields

  input {
    padding: 4px;
    border: 1px solid rgb(201, 201, 201);
    width: 60%;

    &.otpInput {
      // Special styling for OTP input
      letter-spacing: 2px;
      font-size: 1.1em;
      text-align: center;
    }
  }

  label {
    width: 30%; // Wider labels for longer text
    margin-right: 20px;
    text-align: right;
  }
}

.formButton {
  display: block;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin: 15px 0;
  padding: 10px 0;
  box-shadow: 0 0 1px 1px #fff;

  &:hover {
    background-color: rgb(224, 224, 224);
  }
}

.message {
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;

  &.success {
    background-color: rgba(75, 181, 67, 0.1);
    color: #4bb543;
  }

  &.error {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff0000;
  }
}

.passwordRequirements {
  font-size: 0.8em;
  color: #666;
  text-align: center;
  margin: 10px 0;
  padding: 0 20px;
}

.resendCode {
  text-align: center;
  margin-top: 10px;

  button {
    background: none;
    border: none;
    color: #f857a6;
    text-decoration: underline;
    cursor: pointer;

    &:disabled {
      color: #999;
      cursor: not-allowed;
    }
  }
}
