.navbar {
    padding: 16px;
    background-color: rgba(242, 242, 242, 0.8);

    .user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .name {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1.5px solid #fff;
        }
    }
}